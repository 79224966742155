@font-face {
  font-family: "Supria Sans";
  src: url("./fonts/Supria\ Sans.ttf") format("embedded-opentype"),
    /* Internet Explorer */ url("./fonts/Supria\ Sans.ttf") format("woff2"),
    /* Super Modern Browsers */ url("./fonts/Supria\ Sans.ttf") format("woff"),
    /* Pretty Modern Browsers */ url("./fonts/Supria\ Sans.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("./fonts/Supria\ Sans.ttf") format("svg");
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100vh;
  font-family: "Supria Sans";
  color: rgb(168, 168, 168);
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

#loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.2rem;
  background-color: #030303;
  position: fixed;
  top: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
  transition: 0.4s ease;
}

#loader.finished {
  visibility: hidden;
  opacity: 0;
}

#title {
  font-size: 32px;
  color: rgb(236, 236, 236);
}

#file {
  display: flex;
  justify-content: space-between;
  width: 250px;
  padding: 0.2rem;
}

progress {
  padding: 0.2rem;
  border-radius: 0;
  border: 1px solid rgb(189, 189, 189);
  width: 250px;
  height: 20px;
}

progress::-webkit-progress-bar {
  background-color: transparent;
}

progress::-webkit-progress-value {
  background-color: #da8f20;
}

.switch {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: rgb(168, 168, 168);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.iconify {
  display: flex;
  justify-content: center;
  align-items: center;
}

#view,
#view1 {
  position: relative;
  transition: 0.2s;
  cursor: pointer;
}

.currentView {
  color: rgb(236, 236, 236);
}

#view::after {
  content: "";
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 75%;
  background-color: rgb(168, 168, 168);
}

.info {
  font-size: 12px;
  color: rgb(168, 168, 168);
  padding: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 68px - 48px);
  max-width: 300px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.25);
  visibility: visible;
  opacity: 1;
  transition: 0.4s ease;
}

.info.hide {
  visibility: hidden;
  opacity: 0;
}
label {
  /* font-size: 12px; */
  color: rgb(168, 168, 168);
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.25);
  cursor: pointer;
  transition: 0.2s ease;
}

label:hover {
  border: 1px solid #da8f20;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  transition: 0.2s ease;
  opacity: 0;
  visibility: hidden;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
}

/******** Chrome, Safari, Opera and Edge Chromium styles ********/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: rgb(189, 189, 189);
  border-radius: 0.5rem;
  height: 3px;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -6.5px; /* Centers thumb on the track */
  background-color: rgb(189, 189, 189);
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:active::-webkit-slider-thumb {
  background-color: #da8f20;
}

/*********** Firefox styles ***********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: rgb(189, 189, 189);
  border-radius: 0.5rem;
  height: 3px;
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  background-color: rgb(189, 189, 189);
  border: none; /*Removes extra border that FF applies*/
  border-radius: 0.5rem;
  height: 1rem;
  width: 1rem;
}

input[type="range"]:active::-moz-range-thumb {
  background-color: #da8f20;
}

.volume {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  overflow: hidden;
  transition: 0.2s ease;
}

.volume:hover {
  color: rgb(236, 236, 236);
}

.input {
  overflow: hidden;
  width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  height: 1rem;
}

.volume:hover .input {
  width: 100px;
}

.volume:hover input {
  opacity: 1;
  visibility: visible;
}
